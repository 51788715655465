import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import getConfig from 'next/config';
import Router from 'next/router';
import { StateProvider } from '../state/stateProvider';
import SiteLayout from '../layouts/SiteLayout/SiteLayout';
import Snackbar from '../components/Snackbar/Snackbar';
import { initTrackers, trackGA } from '../utils/event-helper';

import '../styles/general.scss';

declare global {
  interface Window { gtag: Function, dataLayer: Object[] }
}

const { publicRuntimeConfig: { config } } = getConfig();

Router.events.on('routeChangeComplete', (url) => {
  if (window) {
    initTrackers();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'optimize.activate' });
    trackGA('page_view', {
      category: 'upgrade',
      page_location: `${window.location.protocol}//${window.location.host}${url}${window.location.search}`,
      page_path: url,
    });
  }
});
const MyApp = ({ Component, pageProps }) => {
  const Layout = Component.layout || SiteLayout;

  useEffect(() => {
    initTrackers();
    if (document.domain?.includes('apester.com')) {
      document.domain = 'apester.com';
    }
  }, []);

  return (
    <StateProvider>
      <Snackbar />
      <Layout pageClass={Component.pageClass}>
        <Script strategy="lazyOnload" src="https://www.googletagmanager.com/gtag/js?id=GTM-MXQJQMM" />
        <Script
          strategy="lazyOnload"
          id="googletagmanager-script"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MXQJQMM');`,
          }}
        />
        <Head>
          <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
          <meta name='viewport'
            content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' />
          <meta name='description' content='Description' />
          <meta name='keywords' content='Keywords' />
          <link rel="manifest" href="/manifest.json" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            rel="preload stylesheet"
            href={`${config.FONTS_URL}/fonts/fonts.general.css`}
            as="style"
          />
          <title>Apester</title>
        </Head>
        <Component {...pageProps} />
      </Layout>
    </StateProvider>
  );
};

export default MyApp;
